export default {
  text: '#000000',
  primary: '#fd7e2d',
  secondary: '#009639',
  tertiary: '',
  quaternary: '',
  background: 'white',
  backgroundSecondary: '#014f1f',
  light: '#FFF',
  dark: '#000000'
}
