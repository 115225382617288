export default {
  heading: 'tekton-pro, sans-serif',
  subheading: 'futura, sans-serif',
  body: 'tekton-pro, sans-serif',
  body2: 'tekton-pro, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Billy, sans-serif',
  googleFonts: [
    'Playfair Display:400,500,600,700',
    'Cabin Sketch',
    'Noto Sans Display:100,200,300,400,500,600,700,800,900',
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Comfortaa',
    'Finger Paint',
    'Limelight',
    'Nunito'
  ],
  customFamilies: ['Billy', 'Blowhole', 'Supersonic Rocketship'],
  customUrls: [
    'https://www.gonation.biz/fonts/billy/billy.css',
    'https://www.gonation.biz/fonts/blowhole/blowhole.css',
    'https://www.gonation.biz/fonts/supersonic-rocketship/supersonic-rocketship.css'
  ]
}
