export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.logoLocationContainer': {
      // display: 'none',
      padding: '0rem'
    },
    '.logo': {
      // display: 'none',
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        maxHeight: ['110px', '120px', '130px', '145px', '160px'],
        // filter: 'brightness(0) invert(1)',
        // maxWidth: ['', '', 'unset', 'unset'],
        filter: 'unset'
      }
    },
    '.logoScrolled': {
      // display: 'none',
      img: {
        maxHeight: ['60px', '70px', '70px', '70px', '70px'],
        filter: 'unset'
      }
    },

    '.container': {
      padding: ['1rem', '1.5rem'],
      position: 'absolute',
      backgroundColor: 'transparent',
      top: '0rem',
      '.smallNavMenu': {
        // margin: '0rem auto 0rem 2rem',
        '.navItem': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'text',
          fontFamily: 'body',
          a: {
            fontSize: '1rem',
            fontFamily: 'body',
            fontWeight: 'bold',
            ':hover': {
              textDecoration: 'underline',
              color: 'secondary'
            }
          }
        },
        '.react-reveal': {
          opacity: '1'
        }
      }
    },
    '.containerScrolled': {
      border: 'none',
      padding: '0.5rem 1rem',
      backgroundColor: 'white',
      // width: 'fit-content',
      position: 'fixed',
      right: '0rem',
      top: '0rem',
      left: 'unset',
      borderBottom: '1px solid',
      borderColor: 'primary',
      '.smallNavMenu': {
        alignItems: 'center',
        '.navItem': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'text',
          fontFamily: 'body',
          fontWeight: 'bold',
          a: {
            fontSize: '1rem',
            fontFamily: 'body',
            fontWeight: 'bold',
            ':hover': {
              textDecoration: 'underline',
              color: 'secondary'
            }
          }
        },
        '.react-reveal': {
          opacity: '1'
        }
      }
    },
    '.hamburger': {
      borderRadius: '100px',
      padding: '0.75rem',
      borderColor: '#000000',
      backgroundColor: '#ffffff',
      // display: ['', '', '', 'none'],
      '> div': {
        backgroundColor: '#000'
      }
    },
    '.directions': {
      variant: 'buttons.primary',
      color: 'white !important',
      padding: '0.75rem 1.5rem'
    },
    '.phoneContainer': {
      display: 'flex',
      border: 'none',
      margin: '0rem 1rem',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      a: {
        backgroundColor: 'primary',
        padding: '0.5rem',
        color: 'light',
        textAlign: 'center',
        margin: '0.5rem 0rem'
      }
    },
    '.hamburgerOpen': {
      borderColor: 'white',
      '> div': {
        backgroundColor: 'light'
      }
    },
    '.navMenuLogo': {
      mb: '2rem'
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'primary',
      '.navItem': {
        a: {
          color: 'light',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
        color: 'white'
      },

      '.socialIconsContainer': {
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          svg: {
            path: {
              fill: 'white'
            }
          }
        }
      }
    },

    '.navBlock': {
      right: 'unset',
      top: '200vh'
    }
  },

  footer: {
    backgroundColor: 'primary',
    borderTop: 'solid 5px',
    borderColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['0.5rem 0.5rem 6rem', '', '0.5rem 3rem'],
    '.image': {
      // filter: 'brightness(0) invert(1)',
      display: 'none'
    },
    '.multiButtonContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        width: 'fit-content',
        fontFamily: 'heading'
      }
    },
    '.contactDetails-container': {
      display: 'none'
    },
    '.copyright': {
      width: '50%',
      color: 'white',
      textAlign: 'center'
    },
    '.socialIconsContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '1rem',
      a: {
        svg: {
          width: '40px',
          height: '40px',
          padding: '0.5rem',
          border: 'solid 1px white',
          borderRadius: '100px',
          ':hover': {
            backgroundColor: 'grey'
          }
        },
        margin: '0.5rem'
      }
    },
    '.gonationLogo': {
      width: '50%'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem'],
    letterSpacing: '-2px',
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    textTransform: 'uppercase',
    fontFamily: 'heading',
    color: 'primary'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
    marginBottom: '1.5rem',
    border: 'none',
    color: 'secondary',
    fontFamily: 'subheading',
    fontWeight: '200'
  },
  text: {
    p: {
      color: 'inherit',
      lineHeight: '1.75',
      fontSize: 'inherit',
      textAlign: 'center'
    }
  },

  sideBySide1: {
    backgroundColor: 'background',
    height: ['', '', '70vh', '90vh'],
    '.lazyload-wrapper': {
      minHeight: ['', '', '50vh'],
      justifyContent: 'flex-start'
    },

    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem'],
      padding: ['2rem 1rem', '2rem', '3rem', '3rem', '4rem']
    },
    '.title': {
      variant: 'customVariants.title'
      // '::before': {
      //   content: "'⫛'",
      //   color: 'primary',
      //   display: 'flex',
      //   flexDirection: 'column',
      //   justifyContent: 'center',
      //   alignItems: 'center',
      //   fontSize: '4rem',
      //   margin: '0rem 2rem 0rem 0rem',
      //   transform: 'rotate(-180deg)',
      // },
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      p: {
        textAlign: 'left'
      }
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      marginBottom: '0rem'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    height: '100vh',
    maxWidth: 'none',
    'slick-slider': {
      img: {
        filter: ['brightness(0.4)', '', 'unset'],
        objectPosition: ['right', '', 'center']
      }
    },

    '.hero_content_container': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      textAlign: 'center',
      color: 'text',
      maxWidth: 'none!important',
      width: ['100%', '', '', 'auto']
    },
    '.logoHero': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '.title': {
      variant: 'customVariants.title',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '::before': {
        content: "'Since 2002'",
        mb: '2rem',
        fontSize: ['1.25rem', '', '1.75rem'],
        color: 'text'
      },
      fontSize: ['2.5rem', '2.5rem', '2.75rem', '2.75rem', '3rem'],
      color: 'primary',
      marginBottom: ['', '', '', '1.5rem']
    },
    '.linksContainer': {
      margin: 'auto'
    },
    // display: none the 3rd child of the .linksContainer
    // '*:nth-child(3)': {
    //   display: 'none'
    // },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'tertiary',
      marginBottom: ['', '', '', '0rem']
    },
    '.text': {
      variant: 'customVariants.text',

      '*': {
        mb: '0rem'
      }
    },
    a: {
      alignSelf: 'center',
      mt: '2rem',
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      width: '280px',
      paddingLeft: '54px',
      paddingRight: '54px'
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  homepageBanner: {
    minHeight: '80vh'
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: '#fcfcfc',
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      maxWidth: '650px'
    }
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide1'
  },
  homepageContact: {
    variant: 'customVariants.sideBySide1'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1',
    figure: {
      height: '100vh'
    },
    '.section': {
      margin: '0rem auto 0rem 0rem',
      padding: '2vw',
      alignItems: 'flex-start',
      maxWidth: '650px',
      textAlign: 'left',
      '.title': {
        color: 'black'
      }
    }
  },

  homepageShout: {
    '.title': {
      variant: 'customVariants.text',
      color: 'secondary',
      fontFamily: 'subheading'
    },
    '.text': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.75rem', '2rem', '2.25rem', '2.5rem']
    },
    '.date': {},
    '.shoutCTABtns ': {}
  },

  homepageBoxes: {
    '.box': {
      margin: '1rem',
      border: 'solid 2px lightgrey',
      flexGrow: 1,
      transition: 'all ease-in-out 0.4s',
      ':hover': {
        borderColor: 'primary'
      },
      '.image': {
        height: '350px'
      },
      '.title': {
        variants: 'customVariants.title',
        color: 'primary',
        mt: '1rem'
      },
      '.subtitle': {
        variants: 'customVariants.subtitle',
        color: 'secondary',

        fontSize: '1em'
      },
      '.text': {
        variant: 'customVariants.text',
        color: 'black',

        '*': {
          textAlign: 'left!important',
          color: 'black',
          fontWeight: 'bold',
          opacity: '1'
        },
        mt: '1rem'
      }
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  bio: {
    variant: 'customVariants.sideBySide1',
    marginTop: '0rem',
    backgroundColor: '#e9e9e2',
    padding: '5rem 2rem 0rem',
    'div.content': {
      width: ['', '', '60%']
    },

    'div.lazyload-wrapper': {
      overflow: 'hidden',
      width: ['', '', '40%'],
      img: {
        objectFit: 'contain',
        objectPosition: 'top'
      }
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  // menu: {},
  menu: {
    backgroundColor: 'black',
    fontFamily: 'tekton-pro',

    '#menuTab': {
      padding: '1rem 1.5rem',
      fontFamily: 'tekton-pro'
    },

    '.menuSectionTitle': {
      variant: 'customVariants.title',
      fontSize: ['1.25rem', '1.5rem', '1.75rem', '1.75rem', '2rem'],
      textAlign: 'left',
      marginBottom: '2rem',
      backgroundColor: 'primary',
      textAlign: 'center',

      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      fontFamily: 'tekton-pro',
      fontWeight: 'bold',
      border: 'none',
      letterSpacing: '1px'
    },

    '.menuSectionDescription': {},

    '.allInContainer': {
      backgroundColor: 'white'
    },
    '.menuContainer': {
      pb: '0rem',
      '.menuItemsContainer': {
        justifyContent: 'flex-start'
      }
    },
    '.smoothies  > .menuContainer .menuSectionTitle, .create-your-own-smoothies  > .menuContainer .menuSectionTitle': {
      display: 'none'
    },

    '.smoothies  > .menuContainer .menuSectionDescription, .create-your-own-smoothies > .menuContainer .menuSectionDescription':
      {
        whiteSpace: 'break-spaces',
        margin: '2rem auto 2rem',
        pt: '2rem',
        display: 'flex',
        justifyContent: 'center'
      },

    '#sec-QXe-qprATgW2VHPXKVBqKw > .menuContainer > .menuSectionTitle': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '::after': {
        content: "'Get One free.'",
        color: 'white',
        fontSize: '1.5rem',
        mt: '1rem'
      }
    },

    '.allInContainerWrapper > .allInContainer > :nth-of-type(2) > .menuContainer > .menuSectionTitle': {
      backgroundColor: '#f15a22'
    },
    '.allInContainerWrapper > .allInContainer > :nth-of-type(3n) > .menuContainer > .menuSectionTitle': {
      backgroundColor: '#0093d3'
    },

    '.allInContainerWrapper > .allInContainer > :nth-of-type(4n) > .menuContainer > .menuSectionTitle': {
      backgroundColor: '#01a751'
    },
    '.allInContainerWrapper > .allInContainer > :nth-of-type(5n) > .menuContainer > .menuSectionTitle': {
      backgroundColor: ' #8ec73f'
    },

    '.allInContainerWrapper > .allInContainer > :nth-of-type(6n) > .menuContainer > .menuSectionTitle': {
      backgroundColor: '#f15a22'
    },

    '.menuItemContainerImgActive': {
      height: '100%',
      '.menuItemInnerContainer': {
        flexDirection: ['column', '', 'row'],
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '317px',
        margin: 'auto',
        '.menuItemContentContainer': {
          width: ['100%', '', '', '70%'],
          color: 'white',
          '.menuItemName': {
            variant: 'customVariants.title',
            fontWeight: 'bold',
            fontFamily: 'tekton-pro',
            fontSize: ['1.25rem', '1.5rem', '1.5rem', '1.5rem', '1.5rem'],
            // width: ['60%', '75%'],
            textShadow: 'none',
            marginBottom: '0rem',
            color: 'black',
            letterSpacing: '1.5px'
          },
          '.itemVariantsContainer': {
            display: 'flex',
            flexWrap: 'wrap'
          },
          '.variantsContainer': {
            display: 'flex',
            flexWrap: 'wrap',
            flexGrow: '1',
            margin: '0.5rem 1rem 0.5rem 0rem'
            // width: ['33.3%'],
          },
          '.variantContainer': {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            fontFamily: 'body2',
            backgroundColor: '#efefefba',
            padding: '0.75rem',
            borderRadius: '10px'
          },
          '.menuItemPriceLabel': {
            fontWeight: 'bold',
            fontSize: '0.8rem',
            marginBottom: '0.25rem'
          },
          '.variantContainerFloatTopRight': {
            fontSize: ['1.25rem', '1.5rem', '2rem']
          },
          '.menuItemLabelOptions': {
            fontSize: '0.7rem',
            maxWidth: '350px'
          },
          '.menuItemPriceVariants': {
            fontSize: '0.7rem',
            maxWidth: '350px'
          },
          '.menuItemDescription': {
            width: '100%',
            // backgroundColor: 'white',
            opacity: '1',
            fontSize: '1rem',
            color: 'black',
            fontFamily: 'tekton-pro',
            lineHeight: '1.5'
          },
          '.menuItemPrice': {
            color: '#20cfca'
          }
        },

        '.menuItemImageContainer': {
          width: ['100%', '', '', '30%'],

          '.menuItemImg': {
            // objectPosition: 'top',
          }
        }
      }
    },
    '.menuItemPageContent': {
      '.itemVariantsContainer': {
        display: 'flex',
        flexWrap: 'wrap'
      },
      '.variantsContainer': {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: '1',
        margin: '0.5rem 1rem 0.5rem 0rem'
        // width: ['33.3%'],
      },
      '.variantContainer': {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'body2',
        backgroundColor: '#efefefba',
        padding: '0.75rem',
        borderRadius: '10px'
      },
      '.menuItemPriceLabel': {
        fontWeight: 'bold',
        fontSize: '0.8rem',
        marginBottom: '0.25rem'
      },
      '.variantContainerFloatTopRight': {
        fontSize: ['1.25rem', '1.5rem', '2rem']
      },
      '.menuItemLabelOptions': {
        fontSize: '0.7rem',
        maxWidth: '350px'
      },
      '.menuItemPriceVariants': {
        fontSize: '0.7rem',
        maxWidth: '350px'
      },
      '.menuItemDescription': {
        width: '100%',
        backgroundColor: 'white',
        opacity: '1',
        lineHeight: '1.7',
        padding: '1rem 0.25rem'
      }
    },
    '.menuItemButton': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.albumName': {
      display: 'none'
    },
    '.albumPhotos': {
      gridGap: '0rem'
    },
    '.albumImage': {
      padding: '0rem'
    },
    '.albumsCTAActive': {
      textDecoration: 'none',
      opacity: '1'
    },
    '.albumsCTA': {
      opacity: '0.8'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',

    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      maxWidth: '1000px',
      color: 'white'
    },
    '.text': {
      color: 'white'
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    marginTop: ['150px', '', '210px'],
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '1.75rem', '1.75rem', '1.75rem', '1.75rem'],
      alignItems: 'center',
      color: 'white',
      fontFamily: 'tekton-pro',
      padding: '1rem'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hoursContainer': {
      '::after': {
        content: "'Closed all major holidays'",
        textAlign: 'left',
        marginTop: '1rem',
        fontWeight: 'bold',
        fontSize: '1.25rem'
      }
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      display: 'none'
    },
    '.textContent': {
      backgroundColor: 'lightgrey'
    },
    '.imageContainer': {
      backgroundColor: 'white',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  },

  // ? =============================
  // ? ====  Supplemental page  ====
  // ? =============================

  supplementalInfo: {
    maxHeight: 'unset',
    background: 'none',
    '.ql-size-large': {
      fontSize: '1.5em'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.text': {
      textAlign: ['left', 'left', 'left', 'left'],
      '*': {
        marginBottom: '0rem'
      }
    },
    alignItems: 'flex-start'
  }
}
